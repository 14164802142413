export const getReferralKeyFromUrl = (referralUrl: string) => {
  // Извлекаем часть URL-адреса с параметрами
  const queryString = referralUrl.split("?")[1];

  // Получаем параметры из URL-адреса
  const urlParams = new URLSearchParams(queryString);
  const ref = urlParams.get("ref");

  return ref;
};

export const createReferralUrlForLoginFriend = (referralUrl: string) => {
  const parsedUrl = new URL(window.location.href);
  const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
  return `${baseUrl}/?ref=${getReferralKeyFromUrl(referralUrl)}`;
};
