import React from "react";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { convertToDateAndWeekdayLiterary, convertToTimestamp } from "@/utils/dateUtils";

import { ElementListHistoryProps } from "../types";

import "./styles.scss";

const ElementListHistory = ({ date, clientInfo, amount }:ElementListHistoryProps) =>
  (
    <div className="history-list-item">
      <p className="history-list-item__date">
        { convertToDateAndWeekdayLiterary(convertToTimestamp(date))}
      </p>
      <div className="history-list-item__green-block">
        <div>
          <p className="history-list-item__client-info">{clientInfo}</p>
          <p className="history-list-item__bonus-text">{`${amount} бесплатных минут на вашем балансе`}</p>
        </div>
        <div className="history-list-item__icon-block">
          <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size16} />
        </div>
      </div>
    </div>
  );

export default ElementListHistory;
