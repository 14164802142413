import React from "react";

import { Button, ButtonSizeEnum } from "@/components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import "./styles.scss";

const EmptyHistory = () =>
  (
    <div className="empty-history-container">
      <Icon type={IconTypeEnum.Search} size={IconSizeEnum.Size32} />
      <p className="empty-history-container__info-text">Пока никто не пополнял баланс по вашему приглашению</p>
      <Button
        text="Пригласить друга"
        size={ButtonSizeEnum.Small}
        onClick={() =>
          console.log("onClick Пригласить друга")}
      />
    </div>
  );

export default EmptyHistory;
