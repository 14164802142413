import { referralInfoTextDataType } from "./types";

export const referralInfoTextData: referralInfoTextDataType[] = [
  {
    id: 1,
    title: "Поделитесь ссылкой с друзьями",
    description: "Публикуйте ссылку в соцсетях или отправляйте друзьям в мессенджерах",
  },
  {
    id: 2,
    title: "Друг регистрируется по ссылке и пополняет баланс",
    description: "Когда сумма пополнений друга достигнет 1000₽ — вы оба получите бонус",
  },
  {
    id: 3,
    title: "Получите бонус за пополнение друга",
    description: "Вам начислим 10 бесплатных минут, а другу — 5 бесплатных минут. Эти минуты можно тратить на звонки",
  },
];
