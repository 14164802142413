import { gql } from "@apollo/client";

export const GET_AFFILIATE_BILLING_EVENT = gql`
  query getAffiliateBillingEvent($limit: Int, $page: Int, $sorting: SortParameters) {
    getAffiliateBillingEvent(limit: $limit, page: $page, sorting: $sorting) {
      affiliateID
      referralStatus
      billingEvent {
        referralEmail
        referralPhone
        createdAt
        amount
        referralName
      }
      pagination {
        totalPages
        totalCount
        perPage
        page
      }
    }
  }
`;
