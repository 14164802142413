import React from "react";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";

const BlockedBlock = () =>
  (
    <div className="unavailable-block">
      <Icon type={IconTypeEnum.Smiley} size={IconSizeEnum.Size32} viewBox={40} />
      <span className="unavailable-block__text">
        Вы нарушили
        {" "}
        <a href="https://lunaro.ru/user-agreement" rel="noopener noreferrer">Правила Сервиса</a>
        ,
        <br />
        поэтому реферальная программа
        <br />
        недоступна
      </span>
    </div>
  );

export default BlockedBlock;
