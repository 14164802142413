import React from "react";

import { AffiliateStatusEnum } from "../../types";

import BlockedBlock from "./BlockedBlock";
import NotAvailableBlock from "./NotAvailable";
import { UnavailableReferralBlockProps } from "./types";
import "./styles.scss";

const UnavailableReferralBlock = ({ affiliateStatus }: UnavailableReferralBlockProps) =>
  (
    <>
      <span className="unavailable-block__title">
        Бесплатные минуты для
        {"\u00a0"}
        вас
        <br className="referral-link-container__desktop-title-text-new-line" />
        {" "}
        и
        {"\u00a0"}
        ваших друзей
      </span>
      <a href="/referral.pdf" className="unavailable-block__detailed-conditions">Подробные условия</a>
      { affiliateStatus === AffiliateStatusEnum.NotAvailable && <NotAvailableBlock />}
      { affiliateStatus === AffiliateStatusEnum.Blocked && <BlockedBlock />}
    </>
  );

export default UnavailableReferralBlock;
