import React from "react";

import ProfileLayout from "@/components/profile/profileLayout";
import ReferralLink from "@/components/profile/referralLink";
import { isBrowser } from "@/utils/env";

const ReferralLinkPage = () => {
  if (!isBrowser()) {
    return null;
  }

  return (
    <ProfileLayout>
      <ReferralLink />
    </ProfileLayout>
  );
};

export default ReferralLinkPage;
