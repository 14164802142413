import React from "react";

import { referralInfoTextData } from "../../constants";
import "./styles.scss";

const InformationBlock = () =>
  (
    <div>
      {referralInfoTextData.map((infoElement) =>
        (
          <div className="information-block">
            <div className="information-block__texts-number-queue">
              <p>{infoElement.id}</p>
            </div>
            <div>
              <p className="referral-link-container__info-description">{infoElement.title}</p>
              <p className="referral-link-container__info-text">{infoElement.description}</p>
            </div>
          </div>
        ))}
    </div>
  );

export default InformationBlock;
