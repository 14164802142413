import React, { useEffect, useState } from "react";

import "./styles.scss";
import { useLazyQuery } from "@apollo/client";

import { Button, ButtonColorEnum, ButtonIconPositionEnum, ButtonSizeEnum } from "@/components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { Tooltip, TooltipPositionEnum } from "@/components/common/tooltip";

import { getAffiliateLink } from "../../graphql/__generated__/getAffiliateLink";
import { GET_AFFILIATE_LINK } from "../../graphql/GET_AFFILIATE_LINK";
import ShareBlock from "../shareBlock";
import { createReferralUrlForLoginFriend } from "../utils";

import { invitationText } from "./constants";
import { CardReferralProps } from "./types";

const CardReferral = ({ isActiveStatus }: CardReferralProps) => {
  const [referralUrlForFriend, setReferralUrlForFriend] = useState("");
  const [getLink, { data, loading, error }] = useLazyQuery<getAffiliateLink>(GET_AFFILIATE_LINK);

  useEffect(() => {
    if (isActiveStatus) {
      getLink({ variables: {} });
    }
  }, [isActiveStatus, getLink]);

  useEffect(() => {
    if (data && !loading && !error) {
      setReferralUrlForFriend(createReferralUrlForLoginFriend(data.getAffiliateLink));
    }
  }, [data, error, loading]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `Lunaro`,
          text: invitationText,
          url: referralUrlForFriend,
        });
        console.log("Контент успешно отправлен");
      } catch (error) {
        console.log("Ошибка при отправке:", error);
      }
    } else {
    // Fallback for browsers that do not support the Web Share API
      console.log("API Web Share не поддерживается в этом браузере.");
    }
  };

  return (
    <div className="card-referral-container">
      <div className="card-referral-container__content">
        <label htmlFor="referral" className="card-referral-container__label">
          Ссылка с бонусом для друга
        </label>
        <div className="card-referral-container__input-block">
          <input
            readOnly
            id="referral"
            value={referralUrlForFriend}
            className="card-referral-container__input"
            type="text"
          />
          <div
            className="card-referral-container__input-icon"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(referralUrlForFriend);
            }}
          >
            <Tooltip text="Ссылка скопирована" position={TooltipPositionEnum.Top}>
              <div className="card-referral-container__copy-referral-block">
                <Icon type={IconTypeEnum.Copy} size={IconSizeEnum.Size24} />
                <span className="card-referral-container__copy-text">Копировать</span>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="card-referral-container__share-button-block">
          <Button
            text="Поделиться"
            icon={IconTypeEnum.Share}
            iconPosition={ButtonIconPositionEnum.Left}
            size={ButtonSizeEnum.Large}
            color={ButtonColorEnum.Dark}
            onClick={handleShare}
            className="card-referral-container__share-button"
          />
          <ShareBlock shareUrl={referralUrlForFriend} />
        </div>
      </div>
    </div>
  );
};
export default CardReferral;
