import React from "react";

import {
  FacebookIcon,
  FacebookShareButton,
  OKIcon,
  OKShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,

} from "react-share";

// import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";

import { invitationText } from "../cardReferral/constants";

import { ShareBlockProps } from "./types";

import "./styles.scss";

const ShareBlock = ({ shareUrl }: ShareBlockProps) =>
  (
    <div className="share-referral-container">
      <div className="share-referral-container__share-block">
        <WhatsappShareButton url={shareUrl} title={invitationText} separator=":: ">
          <WhatsappIcon size={32} round />
          {/* <Icon type={IconTypeEnum.ShareWApp} size={IconSizeEnum.Size48} viewBox={48} /> */}
        </WhatsappShareButton>

        <TelegramShareButton url={shareUrl} title={invitationText}>
          {/* <Icon type={IconTypeEnum.ShareTg} size={IconSizeEnum.Size48} viewBox={48} /> */}
          <TelegramIcon size={32} round />
        </TelegramShareButton>

        <VKShareButton url={shareUrl} title={invitationText}>
          {/* <Icon type={IconTypeEnum.ShareVk} size={IconSizeEnum.Size48} viewBox={48} /> */}
          <VKIcon size={32} round />
        </VKShareButton>

        <OKShareButton url={shareUrl} title={invitationText}>
          {/* <Icon type={IconTypeEnum.ShareOk} size={IconSizeEnum.Size48} viewBox={48} /> */}
          <OKIcon size={32} round />
        </OKShareButton>

        <TwitterShareButton url={shareUrl} title={invitationText}>
          {/* <Icon type={IconTypeEnum.ShareTwitter} size={IconSizeEnum.Size48} viewBox={48} /> */}
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <FacebookShareButton url={shareUrl} title={invitationText}>
          {/* <Icon type={IconTypeEnum.ShareFb} size={IconSizeEnum.Size48} viewBox={48} /> */}
          <FacebookIcon round size={32} />
        </FacebookShareButton>

      </div>
    </div>
  );

export default ShareBlock;
