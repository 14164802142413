import React, { useContext, useEffect } from "react";

import "./styles.scss";
import { useQuery } from "@apollo/client";
import { navigate } from "gatsby";

import UserContext from "@/contexts/User/UserContext";

import CardReferral from "./components/cardReferral";
import HistoryReferral from "./components/historyReferral";
import InformationBlock from "./components/informationBlock";
import UnavailableReferralBlock from "./components/unavailableReferral";
import { getAffiliateRefStatus } from "./graphql/__generated__/getAffiliateRefStatus";
import { GET_AFFILIATE_REF_STATUS } from "./graphql/GET_AFFILIATE_REF_STATUS";
import { AffiliateStatusEnum } from "./types";

const ReferralLink = () => {
  const { data } = useQuery<getAffiliateRefStatus>(GET_AFFILIATE_REF_STATUS, { fetchPolicy: "no-cache" });
  const affiliateRefStatus = data?.getAffiliateRefStatus;
  const isActiveStatus = affiliateRefStatus === AffiliateStatusEnum.Active
  || affiliateRefStatus === AffiliateStatusEnum.Unblocked;

  const { isUserLoggedIn } = useContext(UserContext);

  useEffect(() => {
    if (!isUserLoggedIn) {
      navigate("/?login_form=login");
    }
  }, [isUserLoggedIn]);

  return (
    <div className="referral-link-container">
      {isActiveStatus ? (
        <>
          <div className="referral-link-container__info-container">
            <span className="referral-link-container__info-title">
              Бесплатные минуты для
              {"\u00a0"}
              вас
              <br className="referral-link-container__desktop-title-text-new-line" />
              {" "}
              и
              {"\u00a0"}
              ваших друзей
            </span>
            <p className="referral-link-container__info-text">
              Отправьте другу ссылку — после того, как друг пополнит баланс, вы оба получите
              бесплатные
              {"\u00a0"}
              минуты.
              {" "}
              <a href="/referral.pdf" rel="noopener noreferrer" target="_blank" className="referral-link-container__info-detailed">
                Подробные условия
              </a>
            </p>
            <div className="referral-link-container__card-block">
              <CardReferral isActiveStatus={isActiveStatus} />
            </div>
            <InformationBlock />
          </div>
          <HistoryReferral />
        </>
      ) : (
        <div className="referral-link-container__info-container referral-link-container__info-container--white-mode">
          <UnavailableReferralBlock affiliateStatus={affiliateRefStatus} />
        </div>
      )}
    </div>
  );
};

export default ReferralLink;
