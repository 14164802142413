export type referralInfoTextDataType = {
    id: number,
    title: string,
    description: string

}

export enum AffiliateStatusEnum {
  Active = "active",
  NotAvailable = "not_available",
  Blocked = "blocked",
  Unblocked = "unblocked"
}
